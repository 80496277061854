import React, { useContext, useState } from 'react';
import { NavLink, useLocation, useParams, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './DashboardSideNav.scss';
import { DashboardSideNavLinks } from './navLink';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { PiShootingStarBold } from 'react-icons/pi';
import {
  BsArrowLeftShort,
  BsArrowRightShort,
  BsBellFill,
  BsFillArrowLeftSquareFill,
} from 'react-icons/bs';
import { Avatar, Icon } from '@chakra-ui/react';
import Logo from '../../assets/images/logo.png';
import { ProjectsContext } from '../../context/ProjectsContext';
import { MdOutlineImage } from 'react-icons/md';
import { TbActivityHeartbeat } from 'react-icons/tb';

export default function DashboardSideNav() {
  const { userProfile, logOutFunction } = useContext(AuthContext);
  const location = useLocation();

  return (
    <div className="DashboardSideNav">
      <div className="">
        <img src={Logo} className="h-20 mb-2 mt-20 mx-auto" alt="logo" />
      </div>

      <div className="h-screen relative mt-4">
        <ul className="p-0">
          {DashboardSideNavLinks?.map((link) => {
            const { name, icon, path, id, accountType } = link;
            // const isActive = path == location.pathname;
            function matchRuleShort(str, rule) {
              var escapeRegex = (str) =>
                str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
              return new RegExp(
                "^" + rule.split("*").map(escapeRegex).join(".*") + "$"
              ).test(str);
            }

            // const isActive = path == location.pathname;
            const isActive = matchRuleShort(location.pathname, `${path}*`);
            return (
              <React.Fragment key={id}>
                {(userProfile?.account_type &&
                  userProfile?.account_type.toLowerCase() ===
                    accountType.toLowerCase()) ||
                accountType === 'both' ? (
                  <NavLink to={path}>
                    <li
                      className={`flex justify-between text-lg h-16 items-center pr-2 pl-4 ${
                        isActive
                          ? 'bg-white rounded-tr-full rounded-br-full text-native-blue'
                          : 'text-white'
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <div className="side-nav-icon text-whi">{icon}</div>
                        <span className="nav-link-name">{name}</span>
                      </div>
                      {isActive && (
                        <BsArrowRightShort
                          size={20}
                          className="rounded-full h-10 p-2 w-10 bg-stone-300"
                        />
                      )}
                    </li>
                  </NavLink>
                ) : null}
              </React.Fragment>
            );
          })}
        </ul>
        <div
          onClick={() => logOutFunction()}
          className={`flex mt-10 justify-between text-lg h-14 items-center  pl-4 hover:bg-red-500 rounded-tr-full rounded-br-full text-white `}
        >
          <div className="flex items-center gap-2">
            <BsFillArrowLeftSquareFill />
            <span className="nav-link-name">LogOut</span>
          </div>
          <div className="arrow-container">
            <BsArrowLeftShort className="rounded-full h-10 p-2 w-10 bg-white text-black mr-4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export function DashboardTopNav() {
  const { userProfile, logOutFunction } = React.useContext(AuthContext);
  const [toggleNav, setToggleNav] = React.useState(false);
  const location = useLocation();
  const { projectDetailes, getProjectDetailes } = useContext(ProjectsContext);
  const params = useParams();

  React.useEffect(() => {
    getProjectDetailes();
  }, []);

  return (
    <>
      <div className="h-24 flex items-center justify-between border-b px-6 md:px-10">
        <div className="flex gap-4 items-center">
          <div
            onClick={() => setToggleNav(true)}
            className="flex cursor-pointer items-center justify-center lg:hidden"
          >
            <AiOutlineMenu size={24} />
          </div>
          {DashboardSideNavLinks.filter(
            (item) => item.path == location.pathname
          ).map((item, id) => (
            <div key={id}>
              {location.pathname == item.path && (
                <h2 className="text-[20px] md:text-xl lg:text-2xl m-0">{item.name}</h2>
              )}
            </div>
          ))}
          <div className="flex items-center gap-2">
            {location.pathname ==
              `/dashboard/ongoing-projects/${params.id}` && (
              <>
                <img
                  src="/pajamas_project.svg"
                  className="h-6 w-6 hidden md:block"
                  alt="icon"
                />
                <h2 className="text-[20px] md:text-xl lg:text-2xl m-0 md:hidden w-[50%] truncate ...">
                  {projectDetailes?.data?.project_title}
                </h2>
                <h2 className="text-[20px] md:text-xl lg:text-2xl m-0 max-md:hidden">
                  {projectDetailes?.data?.project_title}
                </h2>
              </>
            )}
            {location.pathname == `/dashboard/activity-tracker` && (
              <>
                <h2 className="text-2xl m-0 md:hidden">Activity Tracker</h2>
                <h2 className="text-2xl m-0 max-md:hidden">Activity Tracker</h2>
              </>
            )}
            {location.pathname == `/dashboard/project-gallery` && (
              <>
                <h2 className="text-2xl m-0 md:hidden">Media Library</h2>
                <h2 className="text-2xl m-0 max-md:hidden">Media Library</h2>
              </>
            )}
            {location.pathname == `/dashboard/subscriptions/${params.id}` && (
              <>
                <h2 className="text-2xl m-0 md:hidden">
                  {projectDetailes?.data?.project_title.slice(0, 25)}...
                </h2>
                <h2 className="text-2xl m-0 max-md:hidden">
                  {projectDetailes?.data?.project_title}
                </h2>
              </>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end gap-4">
          {location.pathname == `/dashboard/ongoing-projects/${params.id}` && (
            // <Link
            //   to={`/dashboard/subscriptions/${params.id}`}
            //   className="subscription-button rounded-full px-2  py-1 bg-transparent flex justify-between items-center"
            // >
            //   <a className="subscription-text">Subscriptions</a>
            // </Link>
            null
          )}
          {location.pathname == `/dashboard/ongoing-projects` && (
            <>
              <Link
                to={`/dashboard/projects-gallery`}
                className="bg-transparent border-1 border-[#000aff] rounded-full px-2 md:px-3 text-white py-2 flex justify-between items-center"
              >
                <MdOutlineImage color='#000aff' />
                <a className="hidden md:block text-native-blue ml-2 font-semibold">Media Library</a>
              </Link>
              <Link
                to={`/dashboard/activity-tracker`}
                className="bg-transparent border-1 border-[#000aff] rounded-full px-2 md:px-3 text-white py-2 flex justify-between items-center"
              >
                <TbActivityHeartbeat color='#000aff' />
                <a className="hidden md:block text-native-blue ml-2 font-semibold">Activity Tracker</a>
              </Link>
            </>
          )}
          {/* <div className="notification-icon-container">
            <Icon as={BsBellFill} w={6} h={6} />
          </div> */}
          <div className='flex flex-row items-center justify-center'>
            <h2 className="text-[20px] md:text-xl lg:text-2xl m-0">{userProfile?.account_balance}</h2>
          </div>
          <div className="hidden md:block">
            <Avatar name={userProfile?.name} src={userProfile?.display_picture} />
          </div>
        </div>

        <div
          className={`transition-transform duration-500 lg:hidden fixed top-0 bottom-0 right-0 flex-col max-w-full bg-native-blue z-50 h-screen w-full ${
            toggleNav ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div
            onClick={() => setToggleNav(false)}
            tabIndex={0}
            className="btn btn-ghost btn-circle mb-4 mt-3 mr-5 self-end"
          >
            <AiOutlineClose className="h-7 w-7 text-white" />
          </div>
          <ul className="p-0">
            {DashboardSideNavLinks?.map((link) => {
              const { name, icon, path, id, accountType } = link;
              const isActive =
                path == location.pathname ||
                (`${path}/${params.id}` ==
                  `${location.pathname}/${params.id}` &&
                  true);

              return (
                <React.Fragment key={id}>
                  {(userProfile?.account_type &&
                    userProfile?.account_type.toLowerCase() ===
                      accountType.toLowerCase()) ||
                  accountType === 'both' ? (
                    <NavLink to={path} onClick={() => setToggleNav(false)}>
                      <li
                        className={`mr-4 flex justify-between text-lg h-16 items-center pr-2 pl-4 ${
                          isActive
                            ? 'bg-white rounded-tr-full rounded-br-full text-native-blue'
                            : 'text-white'
                        } `}
                      >
                        <div className="flex items-center gap-2">
                          <div className="side-nav-icon">{icon}</div>
                          <span className="nav-link-name">{name}</span>
                        </div>
                        {isActive && (
                          <BsArrowRightShort
                            size={20}
                            className="rounded-full h-10 p-2 w-10 bg-stone-300"
                          />
                        )}
                      </li>
                    </NavLink>
                  ) : null}
                </React.Fragment>
              );
            })}
          </ul>
          <div
            onClick={() => logOutFunction()}
            className={`flex mt-10 justify-between text-lg h-14 items-center  pl-4 hover:bg-red-500 rounded-tr-full rounded-br-full text-white `}
          >
            <div className="flex items-center gap-2">
              <BsFillArrowLeftSquareFill />
              <span className="nav-link-name">LogOut</span>
            </div>
            <div className="arrow-container">
              <BsArrowLeftShort className="rounded-full h-10 p-2 w-10 bg-white text-black mr-4" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
